:root {
    
    // Colours
    --c-copy: #0c3142;
    --c-corporate: #C1272D;
    --c-beige: #fce2b9;
    --c-red: var(--c-corporate);
    --c-rot: var(--c-corporate);
    --c-secondary: #006837;
    --c-secondary-light: #A4BDAB;
    --c-green: var(--c-secondary);
    --c-gruen: var(--c-secondary);
    --c-head: var(--c-corporate);
    --c-grey: #cccccc;
    --c-grey-light: #e6e6e6;
    --c-gray: var(--c-grey);
    --c-grau: var(--c-grey);
    
    // Fonts
    --fs-copy: 1.1rem;
    --fs-small: #{pxtoem(12)};
    --fs-large: #{pxtoem(19)};
    --ff-copy: franklin-gothic-urw, sans-serif;
    --ff-head: garage-gothic, sans-serif;
    --ff-thirsty: "thirsty-rough-two", sans-serif;
    --ff-fixed: sans-serif;
    
    // Widths
    --max-width: calc(100% - 4rem);
    --width-margin: 2rem;
    @media(min-width: 1440px) {
        --max-width: 1280px;
        --width-margin: calc(50% - var(--max-width) * 0.5);
    }

    // Margins
    --m-vert: 106px;
    --m-vert-slim: 48px;
    --gap: #{pxtorem(20)};
    // Calcs
    --m-vert-2x: calc(2 * var(--m-vert));
    --gap-2x: calc(2 * var(--gap));
    --gap-3x: calc(3 * var(--gap));
    --gap-4x: calc(4 * var(--gap));
    --gap-6x: calc(6 * var(--gap));
    // Radius
    --radius: #{pxtorem(24)};
    --radius-small: #{pxtorem(8.6)};
    // Schatten
    --shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    --shadow-flat: 0px 2px 4px rgba(0, 0, 0, 0.15),0px 1px 6px rgba(0, 0, 0, 0.1);
    --shadow-flat-pressed: 0px 2px 4px rgba(0, 0, 0, 0.15);
    }