footer {
    background: white;
    padding: var(--gap) var(--width-margin);
    display: flex;
    align-items: center;
    box-shadow: 0 0 3em rgba(0,0,0,0.1);
    @media(max-width: 767px){
        padding: 2em var(--width-margin);
        flex-direction: column;
    }
    justify-content: space-between;
    align-items: center;
    p {
        margin: 0;
        font-weight: bold;
    }
    a {
        color: var(--c-copy);
        text-decoration: none;
    }
    nav {
        text-align: center;
        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media(min-width: 768px){
                justify-content: center;
                flex-direction: row;
            }
            li {
                margin: 0 !important;
                padding: 0 !important;
                @media(min-width: 768px){
                    margin: 0 0 0 2em !important;
                }
            }
        }
    }
}