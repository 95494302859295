section.profile {
    display: grid;
    gap: var(--gap-2x);
    @media(min-width: 768px){
        grid-template-columns: repeat(2, 1fr);
    }
    @media(min-width: 1024px){
        grid-template-columns: 1fr 2fr;
    }
}

div.profile {
    h2 {
        padding-right: var(--gap-2x);
        border-radius: 3em;
        border: solid 2px var(--c-corporate);
        .photo {
            flex-shrink: 0;
            width: 2em;
            border: none;
        }
    }
}

.photo {
    border: solid 4px var(--c-corporate);
    border-radius: 100%;
    overflow: hidden;
    &>div,
    label {
        margin: 0;
        cursor: pointer;
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        opacity: 1;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: solid 4px white;
        border-radius: 100%;
        display: flex;
    }
    input {
        display: none;
    }
}
