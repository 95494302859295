div.has-background {

    padding: var(--gap-2x);

    &.wp-block-group {
        padding: var(--gap-2x) 0;
    }

}

div.has-background {
    &.wp-block-group {
        padding: var(--gap-2x);
    }
}