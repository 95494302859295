header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--gap-2x);

    .logo {
        max-width: 240px;
        width: 50vw;
    }

    nav {
        @media(max-width: 1023px){
            display: none;
        }
        ul {
            display: flex;
            li {
                margin-left: var(--gap);
                span {
                    cursor: pointer;
                    color: var(--c-copy);
                    font-weight: bold;
                    &:hover {
                        color: var(--c-corporate);
                    }
                }
            }
        }
    }
    
    .debugbar {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 9999;
        display: flex;
        align-items: center;
        &>span {
            margin-left: 10px;
        }
    }

}