.selectgame {
    p {
        margin: 0;
        line-height: 1.2;
    }
    .game {
        padding: 1em;
        &>div {
            display: flex;
        }
        background-color: white;
    }
    .players {
        flex-shrink: 0;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        .player {
            display: flex;
            width: 50%;
            align-items: center;
            justify-content: flex-end;
            .avatar {
                margin: 0 0.8em;
                width: 2em;
                height: 2em;
            }
            .info {
                .name {
                    font-weight: bold;
                }
                display: flex;
                flex-direction: column;
                align-items: flex-end
            }
            &+.player {
                .avatar {
                    margin-left: 0;
                }
                flex-direction: row-reverse;
                .info {
                    align-items: flex-start;
                }
            }
        }
    }
}