section.game {
    .games {
        display: grid;
        @media(min-width: 1024px){
            grid-template-columns: repeat(2, 1fr);
            &:not(.isFinished) {
                grid-template-columns: repeat(2, 1fr) 240px;
            }
        }
        grid-gap: var(--gap-2x);
    }
    .video {
        width: 100%;
        padding-bottom: 66%;
        position: relative;
        margin-bottom: var(--gap);
        background: #e5e5e5;
        border-radius: var(--radius);
        box-shadow: var(--shadow);
        overflow: hidden;
        &>span {
            position: absolute;
            left: var(--gap);
            top: var(--gap);
            width: 40px;
            height: 40px;
            padding: 6px;
            border-radius: 20px;
            background: var(--c-corporate);
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                cursor: pointer;
                background: var(--c-copy);
            }
        }
        .webcams {
            display: grid;
            gap: var(--gap);
            @media(min-width: 1024px){
                grid-template-columns: repeat(2, 1fr);
            }
            margin-bottom: var(--m-vert-slim);
        }
        .webcamSelect {
            display: none;
            &.show {
                display: flex;
            }
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(10px);
            background: rgba(255,255,255,0.24);
            &>span {
                width: 86%;
                max-width: 324px;
                margin: 0.5em;
            }
        }
        &>p {
            position: absolute;
            left: 0;
            top: var(--gap-2x);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4em;
            font-weight: bold;
            color: var(--c-corporate);
        }
        video {
            border-radius: var(--radius);
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &>img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 24%;
            opacity: 0.24;
        }
        &>.waiting {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &>span {
                display: flex;
                width: 86%;
                height: 8px;
                justify-content: center;
                &>span {
                    display: block;
                    height: 100%;
                    background: var(--c-corporate);
                    animation: loading 10s infinite; 
                }
            }
            p {
                margin-top: 1em;
                font-weight: bold;
            }
        }
        @keyframes loading {
            0% {
                width: 0;
            }
            100% {
                width: 100%;
            }
        }
    }
    .info {
        h2 {
            margin: 0;
            line-height: 1;
        }
        margin-bottom: var(--gap);
        .error {
            margin-bottom: 2em;
        }
    }
    .player {
        display: flex;
        align-items: stretch;
        box-shadow: var(--shadow);
        border-radius: var(--radius) 4.5em 4.5em var(--radius);
        background: white;
        overflow: hidden;
        &>.score {
            display: flex;
            flex-direction: column;
            width: 16%;
            min-height: 100%;
            position: relative;
            &>div {
                flex-basis: 100%;
                border-radius: calc(0.8 * var(--radius)) 0 0 calc(0.8 * var(--radius));
                &:not(:only-child) {
                    border-radius: calc(0.86 * var(--radius)) 0 0 0;
                    flex-basis: 50.1%;
                    &:last-child {
                        border-top: 0;
                        border-radius: 0 0 0 calc(0.86 * var(--radius));
                        background-color: var(--c-gruen);
                    }
                }
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: white;
                background-color: var(--c-corporate);
                border: solid 2px white;
                p:first-child {
                    font-size: 0.86em;
                    &+p {
                        font-size: 1.4em;
                        font-weight: bold;
                    }
                }
            }
        }
        .board {
            flex-grow: 1;
            display: flex;
            align-items: stretch;
            height: 8rem;
            border: solid 2px white;
            overflow: hidden;
            border-radius: 0 calc(0.86 * 4.5em) calc(0.86 * 4.5em) 0;
            &>div.stats {
                padding: var(--gap);
                flex-grow: 1;
                p.stats {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: var(--fs-small);
                    margin: 0.5em 0 0 0;
                    line-height: 1;
                    img {
                        margin-right: 0.5em;
                        height: var(--fs-small);
                        width: var(--fs-small);
                    }
                }
            }
            &>div.avatar {
                position: relative;
                border-radius: 4.5rem;
                overflow: hidden;
                background: var(--c-grau);
                img {
                    display: flex;
                }
                img:not(.square) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                }
                img.square {
                    opacity: 0;
                    height: 8rem;
                    width: 8rem;
                }
            }
        }
        margin-bottom: var(--gap);
        p,p.h2 {
            margin: 0;
            line-height: 1;
        }
        border: solid 4px white;
        @keyframes currentplayer {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(1);

            }
        }
        &.currentplayer {
                border-color: var(--c-corporate);
            animation: currentplayer 1s forwards;
        }
    }
    @keyframes nextDart {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.2;
        }
    }
    .stats-darts {
        font-size: 0.86em;
        display: flex;
        flex-direction: column-reverse;
        .cnt {
            font-weight: bold;
            padding: 0.7rem;
            color: white;
            display: flex;
            align-items: center;
        }
        .notPlayed {
            display: none !important;
        }
        .set {
            background: white;
            box-shadow: var(--shadow-flat-pressed);
            border-radius: var(--radius);
            border: solid 1px white;
            display: flex;
            align-items: stretch;
            padding: 1px 0 1px 1px;
            overflow: hidden;
            &>.cnt {
                    background: var(--c-corporate);
            }
            .legs {
                flex-grow: 1;
                display: flex;
                flex-direction: column-reverse;
            }
            .leg {
                .inner {
                flex-grow: 1;
                border-left: solid 1px white;
                &:not(:last-child) {
                    border-top: solid 1px white;
                }
                .cnt {
                    cursor: pointer;
                    background: var(--c-secondary);
                }
                display: flex;
                align-items: stretch;
                .data {
                    flex-grow: 1;
                    &>.stats {
                        border-top: solid 1px var(--c-grey-light);
                        display: flex;
                        align-items: center;
                        padding: 0.5rem 1rem;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        span {
                            display: block;
                            padding: 0.2em 0;
                            &.label {
                                font-weight: bold;  
                                font-size: var(--fs-small);
                            }
                        }
                    }
                    .darts {
                        border-top: solid 1px var(--c-grey-light);
                        background: var(--c-grey-light);
                        flex-grow: 1;
                        display: none;
                        &.show {
                            display: grid;
                        }
                        grid-template-columns: repeat(6, 1fr);
                        gap: 1px;
                        &.forceShow {
                            display: grid !important;
                        }
                        .dart {
                            height: 100%;
                            background: white;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0.8rem;
                            &.m2,
                            &.m3 {
                                background: var(--c-corporate);
                                color: white;
                            }
                            &.m3 {
                                background: var(--c-secondary);
                            }
                            &.placeholder {
                                box-shadow: none;
                                border: dashed 1px var(--c-grey);
                                &.nextDart {
                                    border: solid 1px var(--c-rot);
                                    animation: nextDart 1s infinite;
                                }
                            }
                            &>div:not(.finished) {
                                color: rgba(0,0,0,.5);
                            }
                        }
                    }
                }
                }
            }
        }
    }
    .askForDoubles {
        &>div {
            margin-bottom: var(--gap);
        }
    }
    .input {
        &.disabled {
            div:not(.info) {
                opacity: 0.5;
                pointer-events: none;
            }
        }
        display: grid;
        gap: 5px;
        .scoreinput {
            font-size: 1.8em;
            font-weight: bold;
            input {
                text-align: center;
                padding: 0.5em 1em;
                background: var(--c-grey-light);
            }
        }
        .special,
        .multipliers {
            display: grid;
            gap: 5px;
            grid-template-columns: repeat(2, 1fr);
            &.special {
                grid-template-columns: repeat(3, 1fr);
            }
            .score {
                div {
                    background: var(--c-corporate);
                    color: white;
                    border-color: var(--c-corporate);
                    &.score25,
                    &.m3 {
                        border-color: var(--c-secondary);
                        background: var(--c-secondary);
                    }
                    &.zero {
                        border-color: var(--c-copy);
                        background: var(--c-copy);
                    }
                    &:hover {
                        border-color: white;
                    }
                }
            }
        }
        .digit,
        .score {
            &>div {
                position: relative;
                text-align: center;
                padding: 1rem;
                color: var(--c-copy);
                background: white;
                border-radius: var(--radius-small);
                box-shadow: var(--shadow-flat);
                border: solid 2px white;
                &:hover {                
                    border-color: var(--c-rot);    
                    box-shadow: var(--shadow-flat-pressed);
                }
                cursor: pointer;    
                font-weight: bold;
                line-height: 1.2;
                span.multiplier {
                    font-size: 0.8rem;
                    font-weight: normal;
                }
            }
        }
        .digits,
        .scores {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            &.digits:not(.four) {
                grid-template-columns: repeat(3, 1fr);
            }
            gap: 5px;
            .digit,
            .score {
                    width: 100%;
                    padding-bottom: 2.8em;
                    position: relative;
                    &>div {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background: white;
                        &.delete {
                            background: var(--c-rot);
                            color: white;
                            border-color: var(--c-rot);
                            &:hover {
                                border-color: white;
                            }
                        }
                        &.active,
                        &.okay {
                            background: var(--c-gruen);
                            color: white;
                            border-color: var(--c-gruen);
                            &:hover {
                                border-color: white;
                            }
                        }
                        &.disabled {
                            opacity: 0.24;
                            cursor: default !important;
                            pointer-events: none;
                        }
                    }
            }
        }
    }
    .selectplayer {
        margin-bottom: var(--gap);
    }
}