section.games {
    p.small {
        margin-bottom: 0;
    }
    .notdesktop {
        @media(min-width: 768px){
            display: none;
        }
    }
    .notmobile {
        @media(max-width: 767px){
            display: none;
        }
    }
    .games>div+div {
        margin-top: var(--gap);
    }
}