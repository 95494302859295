@font-face {
    font-family: fontname;
    src: url(fontname.ttf);
    font-weight: normal;
}

@font-face {
    font-family: fontname;
    src: url(fontname.ttf);
    font-weight: normal;
}