table {

	width: 100%;
	background: white;

}

td,th {

	border-top: solid 1px #dddddd;
	border-left: solid 1px #dddddd;
	padding: 0.5em 0.7em;

}

th {

	background: var(--c-copy);
	color: white;
	font-weight: bold;

}

th:first-child,
td:first-child {

	border-left: none;

}

tbody tr:nth-child(odd) td {
	background-color: #eeeeee;
}
