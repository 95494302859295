
p.thirsty {
    margin-bottom: var(--gap)
}

.login {
    margin: 0;
    @media(min-height: 1024px){
        margin: 0;
        min-height: 100vh;
    }
    .form {
        margin-top: var(--m-vert-slim);
        width: 100%;
        max-width: 486px;
        background: white;
    }
    &.disabled,
    &.waitForApi {
        .form {
            opacity: 0.5;
        }
    }
}