.league,
.newleague {
    .cols {
        margin-bottom: var(--m-vert-slim);

        &>div .selectplayers {
            min-height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .leaguetable {
        &:not(:last-child) {
            margin-bottom: var(--m-vert-slim);
        }

        .rounds {
            display: grid;
            gap: var(--gap);

            @media(min-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media(min-width: 1024px) {
                grid-template-columns: repeat(3, 1fr);
            }

            &>div {
                padding: var(--gap);
                background: var(--c-grey-light);
                border-radius: var(--radius-small);
            }

            .userIsParticipant {
                font-weight: bold;
            }
        }
    }

    .match {
        display: flex;

        span.vs {
            margin: 0 0.3em;
        }

        .winner {
            text-decoration: underline;
        }
    }

    .standings {
        .row {
            &:not(:first-child) {
                border-top: solid 1px var(--c-grey);
            }
            &.currentPlayer {
                font-weight: bold;
                color: var(--c-corporate);
            }
            &>div {
                padding: 0.3em 0;
                display: grid;
                grid-template-columns: 24px 1fr 128px 32px;
                @media(min-width: 768px){
                    grid-template-columns: 48px 1fr 192px 48px;
                }
                .name {
                    white-space: nowrap;
                }
                :not(.name) {
                    text-align: center;
                }
                .stats {
                    @media(max-width: 599px){
                        display: none;
                    }
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                }
            }

        }
    }
}
