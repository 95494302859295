@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, input, select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: black;
  background: white;
}

ol, ul {
  list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

@font-face {
  font-family: fontname;
  src: url(fontname.ttf);
  font-weight: normal;
}
@font-face {
  font-family: fontname;
  src: url(fontname.ttf);
  font-weight: normal;
}
/*******************************************/
/*
/*  Mixins
*/
.shadow-inset {
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
}

:root {
  --c-copy: #0c3142;
  --c-corporate: #C1272D;
  --c-beige: #fce2b9;
  --c-red: var(--c-corporate);
  --c-rot: var(--c-corporate);
  --c-secondary: #006837;
  --c-secondary-light: #A4BDAB;
  --c-green: var(--c-secondary);
  --c-gruen: var(--c-secondary);
  --c-head: var(--c-corporate);
  --c-grey: #cccccc;
  --c-grey-light: #e6e6e6;
  --c-gray: var(--c-grey);
  --c-grau: var(--c-grey);
  --fs-copy: 1.1rem;
  --fs-small: 0.75em;
  --fs-large: 1.1875em;
  --ff-copy: franklin-gothic-urw, sans-serif;
  --ff-head: garage-gothic, sans-serif;
  --ff-thirsty: "thirsty-rough-two", sans-serif;
  --ff-fixed: sans-serif;
  --max-width: calc(100% - 4rem);
  --width-margin: 2rem;
  --m-vert: 106px;
  --m-vert-slim: 48px;
  --gap: 1.25rem;
  --m-vert-2x: calc(2 * var(--m-vert));
  --gap-2x: calc(2 * var(--gap));
  --gap-3x: calc(3 * var(--gap));
  --gap-4x: calc(4 * var(--gap));
  --gap-6x: calc(6 * var(--gap));
  --radius: 1.5rem;
  --radius-small: 0.5375rem;
  --shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  --shadow-flat: 0px 2px 4px rgba(0, 0, 0, 0.15),0px 1px 6px rgba(0, 0, 0, 0.1);
  --shadow-flat-pressed: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1440px) {
  :root {
    --max-width: 1280px;
    --width-margin: calc(50% - var(--max-width) * 0.5);
  }
}

.typography, article, .content, body {
  color: var(--c-copy);
}
.typography b, article b, .content b, body b,
.typography strong,
article strong,
.content strong,
body strong {
  font-weight: bold;
}
.typography i, article i, .content i, body i,
.typography em,
article em,
.content em,
body em {
  font-style: italic;
}
.typography blockquote, article blockquote, .content blockquote, body blockquote,
.typography pre,
article pre,
.content pre,
body pre {
  padding: 1rem 0;
}
.typography .wp-block-quote, article .wp-block-quote, .content .wp-block-quote, body .wp-block-quote,
.typography blockquote,
article blockquote,
.content blockquote,
body blockquote {
  padding: 0;
  font-size: 1.2em;
  margin: 2em initial;
  border: none;
}
.typography .wp-block-quote p:last-child, article .wp-block-quote p:last-child, .content .wp-block-quote p:last-child, body .wp-block-quote p:last-child,
.typography blockquote p:last-child,
article blockquote p:last-child,
.content blockquote p:last-child,
body blockquote p:last-child {
  margin-bottom: 0;
}
.typography pre, article pre, .content pre, body pre {
  font-family: var(--ff-fixed);
  line-height: 1.5;
  background: black;
  color: white;
}
.typography dd, article dd, .content dd, body dd,
.typography dt,
article dt,
.content dt,
body dt,
.typography ul,
article ul,
.content ul,
body ul,
.typography ol,
article ol,
.content ol,
body ol,
.typography p,
article p,
.content p,
body p {
  margin-bottom: 1em;
  line-height: 1.7;
}
.typography .small, article .small, .content .small, body .small {
  font-size: 0.75em;
}
.typography .abstract, article .abstract, .content .abstract, body .abstract {
  font-weight: bold;
}
@media (min-width: 560px) {
  .typography .abstract, article .abstract, .content .abstract, body .abstract {
    font-size: var(--fs-large);
  }
}
.typography p:last-child, article p:last-child, .content p:last-child, body p:last-child {
  margin-bottom: 0;
}
.typography ul li:not([class]), article ul li:not([class]), .content ul li:not([class]), body ul li:not([class]) {
  margin-top: 10px;
  padding-left: 20px;
  margin-left: 0;
}
.typography ul li:not([class]):nth-child, article ul li:not([class]):nth-child, .content ul li:not([class]):nth-child, body ul li:not([class]):nth-child {
  margin: 0;
}
.typography ul ul, article ul ul, .content ul ul, body ul ul {
  margin-left: 1em;
}
.typography ul li, article ul li, .content ul li, body ul li {
  list-style-type: none;
}
.typography ol li, article ol li, .content ol li, body ol li {
  list-style-type: decimal;
  margin-left: 1em;
  margin-bottom: 1em;
}
.typography ul, article ul, .content ul, body ul,
.typography ol,
article ol,
.content ol,
body ol {
  margin: 1em 0;
  line-height: 1.4;
}
.typography caption, article caption, .content caption, body caption,
.typography .h1,
article .h1,
.content .h1,
body .h1,
.typography h1,
article h1,
.content h1,
body h1,
.typography h2,
article h2,
.content h2,
body h2,
.typography h3,
article h3,
.content h3,
body h3,
.typography h4,
article h4,
.content h4,
body h4,
.typography h5,
article h5,
.content h5,
body h5 {
  margin-top: 0;
  margin-bottom: 0em;
  line-height: 1.2;
  font-family: var(--ff-head);
  font-weight: normal;
}
.typography caption:not(:first-child), article caption:not(:first-child), .content caption:not(:first-child), body caption:not(:first-child),
.typography .h1:not(:first-child),
article .h1:not(:first-child),
.content .h1:not(:first-child),
body .h1:not(:first-child),
.typography h1:not(:first-child),
article h1:not(:first-child),
.content h1:not(:first-child),
body h1:not(:first-child),
.typography h2:not(:first-child),
article h2:not(:first-child),
.content h2:not(:first-child),
body h2:not(:first-child),
.typography h3:not(:first-child),
article h3:not(:first-child),
.content h3:not(:first-child),
body h3:not(:first-child),
.typography h4:not(:first-child),
article h4:not(:first-child),
.content h4:not(:first-child),
body h4:not(:first-child),
.typography h5:not(:first-child),
article h5:not(:first-child),
.content h5:not(:first-child),
body h5:not(:first-child) {
  margin-top: 1em;
}
.typography caption:not(:last-child), article caption:not(:last-child), .content caption:not(:last-child), body caption:not(:last-child),
.typography .h1:not(:last-child),
article .h1:not(:last-child),
.content .h1:not(:last-child),
body .h1:not(:last-child),
.typography h1:not(:last-child),
article h1:not(:last-child),
.content h1:not(:last-child),
body h1:not(:last-child),
.typography h2:not(:last-child),
article h2:not(:last-child),
.content h2:not(:last-child),
body h2:not(:last-child),
.typography h3:not(:last-child),
article h3:not(:last-child),
.content h3:not(:last-child),
body h3:not(:last-child),
.typography h4:not(:last-child),
article h4:not(:last-child),
.content h4:not(:last-child),
body h4:not(:last-child),
.typography h5:not(:last-child),
article h5:not(:last-child),
.content h5:not(:last-child),
body h5:not(:last-child) {
  margin-bottom: 0.5em;
}
.typography caption a, article caption a, .content caption a, body caption a,
.typography .h1 a,
article .h1 a,
.content .h1 a,
body .h1 a,
.typography h1 a,
article h1 a,
.content h1 a,
body h1 a,
.typography h2 a,
article h2 a,
.content h2 a,
body h2 a,
.typography h3 a,
article h3 a,
.content h3 a,
body h3 a,
.typography h4 a,
article h4 a,
.content h4 a,
body h4 a,
.typography h5 a,
article h5 a,
.content h5 a,
body h5 a {
  text-decoration: none;
  color: var(--c-copy);
}
.typography caption a:hover, article caption a:hover, .content caption a:hover, body caption a:hover,
.typography .h1 a:hover,
article .h1 a:hover,
.content .h1 a:hover,
body .h1 a:hover,
.typography h1 a:hover,
article h1 a:hover,
.content h1 a:hover,
body h1 a:hover,
.typography h2 a:hover,
article h2 a:hover,
.content h2 a:hover,
body h2 a:hover,
.typography h3 a:hover,
article h3 a:hover,
.content h3 a:hover,
body h3 a:hover,
.typography h4 a:hover,
article h4 a:hover,
.content h4 a:hover,
body h4 a:hover,
.typography h5 a:hover,
article h5 a:hover,
.content h5 a:hover,
body h5 a:hover {
  color: black;
}
.typography caption:first-child, article caption:first-child, .content caption:first-child, body caption:first-child,
.typography .h1:first-child,
article .h1:first-child,
.content .h1:first-child,
body .h1:first-child,
.typography h1:first-child,
article h1:first-child,
.content h1:first-child,
body h1:first-child,
.typography h2:first-child,
article h2:first-child,
.content h2:first-child,
body h2:first-child,
.typography h3:first-child,
article h3:first-child,
.content h3:first-child,
body h3:first-child,
.typography h4:first-child,
article h4:first-child,
.content h4:first-child,
body h4:first-child,
.typography h5:first-child,
article h5:first-child,
.content h5:first-child,
body h5:first-child {
  margin-top: 0;
}
.typography caption:has(span), article caption:has(span), .content caption:has(span), body caption:has(span),
.typography .h1:has(span),
article .h1:has(span),
.content .h1:has(span),
body .h1:has(span),
.typography h1:has(span),
article h1:has(span),
.content h1:has(span),
body h1:has(span),
.typography h2:has(span),
article h2:has(span),
.content h2:has(span),
body h2:has(span),
.typography h3:has(span),
article h3:has(span),
.content h3:has(span),
body h3:has(span),
.typography h4:has(span),
article h4:has(span),
.content h4:has(span),
body h4:has(span),
.typography h5:has(span),
article h5:has(span),
.content h5:has(span),
body h5:has(span) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.typography .h1, article .h1, .content .h1, body .h1, .typography h1, article h1, .content h1, body h1 {
  font-family: var(--ff-head);
  font-size: 4em;
  font-weight: 700;
  color: var(--c-rot);
  text-shadow: 1.5px 1.5px 0 white, 3px 3px 0 var(--c-gruen);
  text-transform: uppercase;
}
.typography .h2, article .h2, .content .h2, body .h2,
.typography h2,
article h2,
.content h2,
body h2 {
  font-size: clamp(32px, 4vw, 48px);
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--c-rot);
  text-shadow: 1.5px 1.5px 0 white, 3px 3px 0 var(--c-gruen);
}
.typography .h2 span.flat, article .h2 span.flat, .content .h2 span.flat, body .h2 span.flat,
.typography h2 span.flat,
article h2 span.flat,
.content h2 span.flat,
body h2 span.flat {
  text-shadow: none;
  color: var(--c-gruen);
}
.typography caption, article caption, .content caption, body caption,
.typography h3,
article h3,
.content h3,
body h3 {
  font-size: clamp(20px, 2.4vw, 32px);
  text-transform: uppercase;
  font-weight: bold;
}
@media (min-width: 560px) {
  .typography caption, article caption, .content caption, body caption,
  .typography h3,
  article h3,
  .content h3,
  body h3 {
    font-size: 1.5em;
  }
}
.typography h4, article h4, .content h4, body h4,
.typography h5,
article h5,
.content h5,
body h5 {
  font-size: 1.2em;
}
.typography h1 + *, article h1 + *, .content h1 + *, body h1 + *,
.typography h2 + *,
article h2 + *,
.content h2 + *,
body h2 + *,
.typography h3 + *,
article h3 + *,
.content h3 + *,
body h3 + *,
.typography .coltop + h1,
article .coltop + h1,
.content .coltop + h1,
body .coltop + h1,
.typography .coltop + h2,
article .coltop + h2,
.content .coltop + h2,
body .coltop + h2,
.typography .coltop + h3,
article .coltop + h3,
.content .coltop + h3,
body .coltop + h3,
.typography .coltop + h4,
article .coltop + h4,
.content .coltop + h4,
body .coltop + h4,
.typography .coltop + h5,
article .coltop + h5,
.content .coltop + h5,
body .coltop + h5,
.typography a[name=copytop] + h1,
article a[name=copytop] + h1,
.content a[name=copytop] + h1,
body a[name=copytop] + h1,
.typography a[name=copytop] + h2,
article a[name=copytop] + h2,
.content a[name=copytop] + h2,
body a[name=copytop] + h2,
.typography a[name=copytop] + h3,
article a[name=copytop] + h3,
.content a[name=copytop] + h3,
body a[name=copytop] + h3,
.typography a[name=copytop] + h4,
article a[name=copytop] + h4,
.content a[name=copytop] + h4,
body a[name=copytop] + h4,
.typography a[name=copytop] + h5,
article a[name=copytop] + h5,
.content a[name=copytop] + h5,
body a[name=copytop] + h5 {
  margin-top: 0;
}
.typography a, article a, .content a, body a {
  color: var(--c-corporate);
}
.typography a:hover, article a:hover, .content a:hover, body a:hover {
  color: var(--c-head);
}
.typography a:hover:active, article a:hover:active, .content a:hover:active, body a:hover:active {
  color: var(--c-corporate);
}
.typography .wp-caption p, article .wp-caption p, .content .wp-caption p, body .wp-caption p {
  font-size: 0.8em;
  margin: 0.5em 0 1em 0;
}

.thirsty {
  font-family: var(--ff-thirsty);
  font-size: 2rem;
}

html {
  zoom: 100%;
  box-sizing: border-box;
}
html * {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: var(--ff-copy);
  font-size: var(--fs-copy);
  -webkit-font-smoothing: antialiased;
  color: var(--c-copy);
  background: url("/images/Texture-Portrait-AdobeStock_617735627.gif") no-repeat center center fixed;
  background-size: cover;
}
@media (min-aspect-ratio: 1/1) {
  body {
    background: url("/images/Texture-AdobeStock_617735627.gif") no-repeat center center fixed;
    background-size: cover;
  }
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#app main {
  padding: var(--gap-2x) var(--width-margin);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--gap-2x);
}
header .logo {
  max-width: 240px;
  width: 50vw;
}
@media (max-width: 1023px) {
  header nav {
    display: none;
  }
}
header nav ul {
  display: flex;
}
header nav ul li {
  margin-left: var(--gap);
}
header nav ul li span {
  cursor: pointer;
  color: var(--c-copy);
  font-weight: bold;
}
header nav ul li span:hover {
  color: var(--c-corporate);
}
header .debugbar {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
  display: flex;
  align-items: center;
}
header .debugbar > span {
  margin-left: 10px;
}

.content ._brlbs-switch-consent, .content > .wp-block-columns, .content > .wrapThis, .content > div.wp-block-group, .content > ul, .content > ol, .content > blockquote, .content > form, .content > p, .content > dd, .content > h1, .content > h2, .content > h3, .content > h4, .content > h5, .content > h6, .content > figure {
  max-width: var(--article-width);
  margin-left: var(--width-margin);
}
@media (max-width: 1179px) {
  .content ._brlbs-switch-consent, .content > .wp-block-columns, .content > .wrapThis, .content > div.wp-block-group, .content > ul, .content > ol, .content > blockquote, .content > form, .content > p, .content > dd, .content > h1, .content > h2, .content > h3, .content > h4, .content > h5, .content > h6, .content > figure {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.content > ul {
  margin-top: 2em;
  margin-bottom: 2em;
}
.content h2 + ul {
  margin-top: 1em;
}
.content > .wp-block-buttons, .content > .wp-block-columns, .content > .wp-block-group {
  margin-left: auto;
  margin-right: auto;
}
.content > .wp-block-group {
  margin-bottom: 1em;
}
.content li {
  background: none;
  padding-left: 0;
}
.content .wp-block-columns {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.content .wp-block-group__inner-container .wp-block-columns {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.content .wp-block-df-block-df-abstract {
  margin-bottom: 3rem;
}
.content div.has-background {
  padding: var(--gap-2x);
}
.content div.has-background.wp-block-group {
  padding: var(--gap-2x) 0;
}
.content div.has-background.wp-block-group {
  padding: var(--gap-2x);
}

p.thirsty {
  margin-bottom: var(--gap);
}

.login {
  margin: 0;
}
@media (min-height: 1024px) {
  .login {
    margin: 0;
    min-height: 100vh;
  }
}
.login .form {
  margin-top: var(--m-vert-slim);
  width: 100%;
  max-width: 486px;
  background: white;
}
.login.disabled .form, .login.waitForApi .form {
  opacity: 0.5;
}

selectplayers:not(:first-child) {
  margin-top: var(--m-vert);
}
selectplayers:not(:last-child) {
  margin-bottom: var(--m-vert);
}

.selectplayers .select {
  margin-top: var(--gap-2x);
  display: grid;
  grid-gap: var(--gap);
}
@media (min-width: 768px) {
  .selectplayers .select {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .selectplayers .select {
    grid-template-columns: repeat(3, 1fr);
  }
}
.selectplayers .select > div {
  margin-top: 6px;
  border-radius: var(--radius-small);
  background: var(--c-grey-light);
  display: flex;
  justify-content: space-between;
}
.selectplayers .select > div > span {
  display: block;
  padding: 0.8em 0.8em;
}
.selectplayers .select > div .stats {
  font-weight: bold;
  border-left: solid 1px white;
  padding: 0.8em 1.4em;
  width: 6rem;
  text-align: right;
  font-size: var(--fs-small);
  line-height: 1.4;
}
.selectplayers .select > div .stats > span {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.selectplayers .select > div .stats > span img {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
}
.selectplayers .select > div .stats .na {
  opacity: 0.24;
}
.selectplayers .select > div:hover {
  background-color: var(--c-corporate);
  color: white;
  cursor: pointer;
}
.selectplayers .select > div.selected {
  background-color: var(--c-secondary);
  color: white;
}
.selectplayers .submit {
  margin-top: var(--gap-2x);
}

section.games p.small {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  section.games .notdesktop {
    display: none;
  }
}
@media (max-width: 767px) {
  section.games .notmobile {
    display: none;
  }
}
section.games .games > div + div {
  margin-top: var(--gap);
}

.selectgame p {
  margin: 0;
  line-height: 1.2;
}
.selectgame .game {
  padding: 1em;
  background-color: white;
}
.selectgame .game > div {
  display: flex;
}
.selectgame .players {
  flex-shrink: 0;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectgame .players .player {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;
}
.selectgame .players .player .avatar {
  margin: 0 0.8em;
  width: 2em;
  height: 2em;
}
.selectgame .players .player .info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.selectgame .players .player .info .name {
  font-weight: bold;
}
.selectgame .players .player + .player {
  flex-direction: row-reverse;
}
.selectgame .players .player + .player .avatar {
  margin-left: 0;
}
.selectgame .players .player + .player .info {
  align-items: flex-start;
}

section.game .games {
  display: grid;
  grid-gap: var(--gap-2x);
}
@media (min-width: 1024px) {
  section.game .games {
    grid-template-columns: repeat(2, 1fr);
  }
  section.game .games:not(.isFinished) {
    grid-template-columns: repeat(2, 1fr) 240px;
  }
}
section.game .video {
  width: 100%;
  padding-bottom: 66%;
  position: relative;
  margin-bottom: var(--gap);
  background: #e5e5e5;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  overflow: hidden;
}
section.game .video > span {
  position: absolute;
  left: var(--gap);
  top: var(--gap);
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 20px;
  background: var(--c-corporate);
  display: flex;
  align-items: center;
  justify-content: center;
}
section.game .video > span:hover {
  cursor: pointer;
  background: var(--c-copy);
}
section.game .video .webcams {
  display: grid;
  gap: var(--gap);
  margin-bottom: var(--m-vert-slim);
}
@media (min-width: 1024px) {
  section.game .video .webcams {
    grid-template-columns: repeat(2, 1fr);
  }
}
section.game .video .webcamSelect {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.24);
}
section.game .video .webcamSelect.show {
  display: flex;
}
section.game .video .webcamSelect > span {
  width: 86%;
  max-width: 324px;
  margin: 0.5em;
}
section.game .video > p {
  position: absolute;
  left: 0;
  top: var(--gap-2x);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  font-weight: bold;
  color: var(--c-corporate);
}
section.game .video video {
  border-radius: var(--radius);
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section.game .video > img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24%;
  opacity: 0.24;
}
section.game .video > .waiting {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section.game .video > .waiting > span {
  display: flex;
  width: 86%;
  height: 8px;
  justify-content: center;
}
section.game .video > .waiting > span > span {
  display: block;
  height: 100%;
  background: var(--c-corporate);
  animation: loading 10s infinite;
}
section.game .video > .waiting p {
  margin-top: 1em;
  font-weight: bold;
}
@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
section.game .info {
  margin-bottom: var(--gap);
}
section.game .info h2 {
  margin: 0;
  line-height: 1;
}
section.game .info .error {
  margin-bottom: 2em;
}
section.game .player {
  display: flex;
  align-items: stretch;
  box-shadow: var(--shadow);
  border-radius: var(--radius) 4.5em 4.5em var(--radius);
  background: white;
  overflow: hidden;
  margin-bottom: var(--gap);
  border: solid 4px white;
}
section.game .player > .score {
  display: flex;
  flex-direction: column;
  width: 16%;
  min-height: 100%;
  position: relative;
}
section.game .player > .score > div {
  flex-basis: 100%;
  border-radius: calc(0.8 * var(--radius)) 0 0 calc(0.8 * var(--radius));
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--c-corporate);
  border: solid 2px white;
}
section.game .player > .score > div:not(:only-child) {
  border-radius: calc(0.86 * var(--radius)) 0 0 0;
  flex-basis: 50.1%;
}
section.game .player > .score > div:not(:only-child):last-child {
  border-top: 0;
  border-radius: 0 0 0 calc(0.86 * var(--radius));
  background-color: var(--c-gruen);
}
section.game .player > .score > div p:first-child {
  font-size: 0.86em;
}
section.game .player > .score > div p:first-child + p {
  font-size: 1.4em;
  font-weight: bold;
}
section.game .player .board {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  height: 8rem;
  border: solid 2px white;
  overflow: hidden;
  border-radius: 0 3.87em 3.87em 0;
}
section.game .player .board > div.stats {
  padding: var(--gap);
  flex-grow: 1;
}
section.game .player .board > div.stats p.stats {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-small);
  margin: 0.5em 0 0 0;
  line-height: 1;
}
section.game .player .board > div.stats p.stats img {
  margin-right: 0.5em;
  height: var(--fs-small);
  width: var(--fs-small);
}
section.game .player .board > div.avatar {
  position: relative;
  border-radius: 4.5rem;
  overflow: hidden;
  background: var(--c-grau);
}
section.game .player .board > div.avatar img {
  display: flex;
}
section.game .player .board > div.avatar img:not(.square) {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
section.game .player .board > div.avatar img.square {
  opacity: 0;
  height: 8rem;
  width: 8rem;
}
section.game .player p, section.game .player p.h2 {
  margin: 0;
  line-height: 1;
}
@keyframes currentplayer {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
section.game .player.currentplayer {
  border-color: var(--c-corporate);
  animation: currentplayer 1s forwards;
}
@keyframes nextDart {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
section.game .stats-darts {
  font-size: 0.86em;
  display: flex;
  flex-direction: column-reverse;
}
section.game .stats-darts .cnt {
  font-weight: bold;
  padding: 0.7rem;
  color: white;
  display: flex;
  align-items: center;
}
section.game .stats-darts .notPlayed {
  display: none !important;
}
section.game .stats-darts .set {
  background: white;
  box-shadow: var(--shadow-flat-pressed);
  border-radius: var(--radius);
  border: solid 1px white;
  display: flex;
  align-items: stretch;
  padding: 1px 0 1px 1px;
  overflow: hidden;
}
section.game .stats-darts .set > .cnt {
  background: var(--c-corporate);
}
section.game .stats-darts .set .legs {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
}
section.game .stats-darts .set .leg .inner {
  flex-grow: 1;
  border-left: solid 1px white;
  display: flex;
  align-items: stretch;
}
section.game .stats-darts .set .leg .inner:not(:last-child) {
  border-top: solid 1px white;
}
section.game .stats-darts .set .leg .inner .cnt {
  cursor: pointer;
  background: var(--c-secondary);
}
section.game .stats-darts .set .leg .inner .data {
  flex-grow: 1;
}
section.game .stats-darts .set .leg .inner .data > .stats {
  border-top: solid 1px var(--c-grey-light);
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
section.game .stats-darts .set .leg .inner .data > .stats span {
  display: block;
  padding: 0.2em 0;
}
section.game .stats-darts .set .leg .inner .data > .stats span.label {
  font-weight: bold;
  font-size: var(--fs-small);
}
section.game .stats-darts .set .leg .inner .data .darts {
  border-top: solid 1px var(--c-grey-light);
  background: var(--c-grey-light);
  flex-grow: 1;
  display: none;
  grid-template-columns: repeat(6, 1fr);
  gap: 1px;
}
section.game .stats-darts .set .leg .inner .data .darts.show {
  display: grid;
}
section.game .stats-darts .set .leg .inner .data .darts.forceShow {
  display: grid !important;
}
section.game .stats-darts .set .leg .inner .data .darts .dart {
  height: 100%;
  background: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
}
section.game .stats-darts .set .leg .inner .data .darts .dart.m2, section.game .stats-darts .set .leg .inner .data .darts .dart.m3 {
  background: var(--c-corporate);
  color: white;
}
section.game .stats-darts .set .leg .inner .data .darts .dart.m3 {
  background: var(--c-secondary);
}
section.game .stats-darts .set .leg .inner .data .darts .dart.placeholder {
  box-shadow: none;
  border: dashed 1px var(--c-grey);
}
section.game .stats-darts .set .leg .inner .data .darts .dart.placeholder.nextDart {
  border: solid 1px var(--c-rot);
  animation: nextDart 1s infinite;
}
section.game .stats-darts .set .leg .inner .data .darts .dart > div:not(.finished) {
  color: rgba(0, 0, 0, 0.5);
}
section.game .askForDoubles > div {
  margin-bottom: var(--gap);
}
section.game .input {
  display: grid;
  gap: 5px;
}
section.game .input.disabled div:not(.info) {
  opacity: 0.5;
  pointer-events: none;
}
section.game .input .scoreinput {
  font-size: 1.8em;
  font-weight: bold;
}
section.game .input .scoreinput input {
  text-align: center;
  padding: 0.5em 1em;
  background: var(--c-grey-light);
}
section.game .input .special,
section.game .input .multipliers {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(2, 1fr);
}
section.game .input .special.special,
section.game .input .multipliers.special {
  grid-template-columns: repeat(3, 1fr);
}
section.game .input .special .score div,
section.game .input .multipliers .score div {
  background: var(--c-corporate);
  color: white;
  border-color: var(--c-corporate);
}
section.game .input .special .score div.score25, section.game .input .special .score div.m3,
section.game .input .multipliers .score div.score25,
section.game .input .multipliers .score div.m3 {
  border-color: var(--c-secondary);
  background: var(--c-secondary);
}
section.game .input .special .score div.zero,
section.game .input .multipliers .score div.zero {
  border-color: var(--c-copy);
  background: var(--c-copy);
}
section.game .input .special .score div:hover,
section.game .input .multipliers .score div:hover {
  border-color: white;
}
section.game .input .digit > div,
section.game .input .score > div {
  position: relative;
  text-align: center;
  padding: 1rem;
  color: var(--c-copy);
  background: white;
  border-radius: var(--radius-small);
  box-shadow: var(--shadow-flat);
  border: solid 2px white;
  cursor: pointer;
  font-weight: bold;
  line-height: 1.2;
}
section.game .input .digit > div:hover,
section.game .input .score > div:hover {
  border-color: var(--c-rot);
  box-shadow: var(--shadow-flat-pressed);
}
section.game .input .digit > div span.multiplier,
section.game .input .score > div span.multiplier {
  font-size: 0.8rem;
  font-weight: normal;
}
section.game .input .digits,
section.game .input .scores {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}
section.game .input .digits.digits:not(.four),
section.game .input .scores.digits:not(.four) {
  grid-template-columns: repeat(3, 1fr);
}
section.game .input .digits .digit,
section.game .input .digits .score,
section.game .input .scores .digit,
section.game .input .scores .score {
  width: 100%;
  padding-bottom: 2.8em;
  position: relative;
}
section.game .input .digits .digit > div,
section.game .input .digits .score > div,
section.game .input .scores .digit > div,
section.game .input .scores .score > div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
}
section.game .input .digits .digit > div.delete,
section.game .input .digits .score > div.delete,
section.game .input .scores .digit > div.delete,
section.game .input .scores .score > div.delete {
  background: var(--c-rot);
  color: white;
  border-color: var(--c-rot);
}
section.game .input .digits .digit > div.delete:hover,
section.game .input .digits .score > div.delete:hover,
section.game .input .scores .digit > div.delete:hover,
section.game .input .scores .score > div.delete:hover {
  border-color: white;
}
section.game .input .digits .digit > div.active, section.game .input .digits .digit > div.okay,
section.game .input .digits .score > div.active,
section.game .input .digits .score > div.okay,
section.game .input .scores .digit > div.active,
section.game .input .scores .digit > div.okay,
section.game .input .scores .score > div.active,
section.game .input .scores .score > div.okay {
  background: var(--c-gruen);
  color: white;
  border-color: var(--c-gruen);
}
section.game .input .digits .digit > div.active:hover, section.game .input .digits .digit > div.okay:hover,
section.game .input .digits .score > div.active:hover,
section.game .input .digits .score > div.okay:hover,
section.game .input .scores .digit > div.active:hover,
section.game .input .scores .digit > div.okay:hover,
section.game .input .scores .score > div.active:hover,
section.game .input .scores .score > div.okay:hover {
  border-color: white;
}
section.game .input .digits .digit > div.disabled,
section.game .input .digits .score > div.disabled,
section.game .input .scores .digit > div.disabled,
section.game .input .scores .score > div.disabled {
  opacity: 0.24;
  cursor: default !important;
  pointer-events: none;
}
section.game .selectplayer {
  margin-bottom: var(--gap);
}

section.profile {
  display: grid;
  gap: var(--gap-2x);
}
@media (min-width: 768px) {
  section.profile {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  section.profile {
    grid-template-columns: 1fr 2fr;
  }
}

div.profile h2 {
  padding-right: var(--gap-2x);
  border-radius: 3em;
  border: solid 2px var(--c-corporate);
}
div.profile h2 .photo {
  flex-shrink: 0;
  width: 2em;
  border: none;
}

.photo {
  border: solid 4px var(--c-corporate);
  border-radius: 100%;
  overflow: hidden;
}
.photo > div,
.photo label {
  margin: 0;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  opacity: 1;
}
.photo img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: solid 4px white;
  border-radius: 100%;
  display: flex;
}
.photo input {
  display: none;
}

.league .cols,
.newleague .cols {
  margin-bottom: var(--m-vert-slim);
}
.league .cols > div .selectplayers,
.newleague .cols > div .selectplayers {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.league .leaguetable:not(:last-child),
.newleague .leaguetable:not(:last-child) {
  margin-bottom: var(--m-vert-slim);
}
.league .leaguetable .rounds,
.newleague .leaguetable .rounds {
  display: grid;
  gap: var(--gap);
}
@media (min-width: 768px) {
  .league .leaguetable .rounds,
  .newleague .leaguetable .rounds {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .league .leaguetable .rounds,
  .newleague .leaguetable .rounds {
    grid-template-columns: repeat(3, 1fr);
  }
}
.league .leaguetable .rounds > div,
.newleague .leaguetable .rounds > div {
  padding: var(--gap);
  background: var(--c-grey-light);
  border-radius: var(--radius-small);
}
.league .leaguetable .rounds .userIsParticipant,
.newleague .leaguetable .rounds .userIsParticipant {
  font-weight: bold;
}
.league .match,
.newleague .match {
  display: flex;
}
.league .match span.vs,
.newleague .match span.vs {
  margin: 0 0.3em;
}
.league .match .winner,
.newleague .match .winner {
  text-decoration: underline;
}
.league .standings .row:not(:first-child),
.newleague .standings .row:not(:first-child) {
  border-top: solid 1px var(--c-grey);
}
.league .standings .row.currentPlayer,
.newleague .standings .row.currentPlayer {
  font-weight: bold;
  color: var(--c-corporate);
}
.league .standings .row > div,
.newleague .standings .row > div {
  padding: 0.3em 0;
  display: grid;
  grid-template-columns: 24px 1fr 128px 32px;
}
@media (min-width: 768px) {
  .league .standings .row > div,
  .newleague .standings .row > div {
    grid-template-columns: 48px 1fr 192px 48px;
  }
}
.league .standings .row > div .name,
.newleague .standings .row > div .name {
  white-space: nowrap;
}
.league .standings .row > div :not(.name),
.newleague .standings .row > div :not(.name) {
  text-align: center;
}
.league .standings .row > div .stats,
.newleague .standings .row > div .stats {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 599px) {
  .league .standings .row > div .stats,
  .newleague .standings .row > div .stats {
    display: none;
  }
}

article {
  clear: both;
  margin: 0 auto;
}
article figure {
  margin: 0;
}

table {
  width: 100%;
  background: white;
}

td, th {
  border-top: solid 1px #dddddd;
  border-left: solid 1px #dddddd;
  padding: 0.5em 0.7em;
}

th {
  background: var(--c-copy);
  color: white;
  font-weight: bold;
}

th:first-child,
td:first-child {
  border-left: none;
}

tbody tr:nth-child(odd) td {
  background-color: #eeeeee;
}

.panel {
  padding: var(--gap-2x);
  max-width: 640px;
}
.panel:has(.column) {
  width: 100%;
  max-width: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gap-2x);
}

.form {
  padding: var(--gap-2x);
  box-shadow: var(--shadow);
  border-radius: var(--radius);
}
.form:not(:first-child) {
  margin-top: var(--gap-2x);
}
.form:not(:last-child) {
  margin-bottom: var(--gap-2x);
}

.formrow {
  margin-bottom: var(--gap);
}
.formrow.cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--gap);
}
.formrow.cols.cols3 {
  grid-template-columns: repeat(3, 1fr);
}

label {
  margin-bottom: 1em;
  display: block;
  font-size: 0.86em;
}

span.link {
  text-decoration: underline;
}

select,
textarea,
input[type=email],
input[type=password],
input[type=search],
input[type=text] {
  font-family: var(--ff-cop24);
  padding: 0.8em;
  border: solid 1px var(--c-grey);
  margin: 0;
  width: 100%;
  font-size: 1em;
  border-radius: var(--radius-small);
}
select:focus,
textarea:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=text]:focus {
  outline: none;
  border-color: var(--c-corporate);
}
select:hover,
textarea:hover,
input[type=email]:hover,
input[type=password]:hover,
input[type=search]:hover,
input[type=text]:hover {
  border-color: var(--c-corporate);
}

input[type=checkbox],
input[type=radio],
input[type=submit],
input[type=button],
select {
  cursor: pointer;
}

.switch:not(.button) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px;
  width: 100%;
}
.switch:not(.button) > div {
  padding: 0.8em;
  border: solid 2px var(--c-grey-light);
  background: var(--c-grey-light);
  color: var(--c-grey);
  text-align: center;
  cursor: pointer;
  border-radius: 0 var(--radius-small) var(--radius-small) 0;
  font-size: 1em;
}
.switch:not(.button) > div:first-child {
  border-radius: var(--radius-small) 0 0 var(--radius-small);
}
.switch:not(.button) > div:hover {
  color: var(--c-copy);
}
.switch:not(.button) > div.on {
  background: var(--c-corporate);
  border-color: var(--c-corporate);
  color: white;
}

.tutorial {
  margin: var(--gap-2x) 0;
  border: solid 4px var(--c-corporate);
  border-radius: var(--radius-small);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gap-2x);
  text-align: center;
}

.cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--gap);
}
.cols.grey > div {
  padding: var(--gap);
  background: var(--c-grey-light);
  border-radius: var(--radius-small);
}

div.statstable {
  padding: var(--gap);
  border: solid 2px var(--c-corporate);
  margin-bottom: var(--gap-2x);
}
div.statstable p {
  margin: 0;
  display: flex;
  justify-content: space-between;
}
div.statstable p span.label {
  font-weight: bold;
}

span.link {
  color: var(--c-corporate);
  text-decoration: underline;
  cursor: pointer;
}
span.link:hover {
  color: var(--c-copy);
}

a.button,
.button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0.7rem 1.5rem 0.6rem 1.5rem;
  font-size: 1rem;
  background: var(--c-corporate);
  color: white;
  text-decoration: none;
  border-radius: var(--radius-small);
  box-shadow: none;
  border: none;
  text-shadow: none;
  font-family: var(--ff-copy);
  font-weight: bold;
  text-transform: uppercase;
  --webkit-apperance: none;
  text-align: center;
}
a.button.block,
.button.block {
  display: block;
  width: 100%;
}
a.button.small, a.button.mini,
.button.small,
.button.mini {
  padding: 0.4em 1em 0.3em 1em;
  font-size: 0.86em;
}
a.button.transparent,
.button.transparent {
  background: transparent;
  color: var(--c-copy);
}
a.button.transparent:hover,
.button.transparent:hover {
  background: var(--c-corporate);
  color: white;
}
a.button.green,
.button.green {
  background: var(--c-gruen);
}
a.button.secondary,
.button.secondary {
  background: white;
  border: solid 1px var(--c-secondary);
  color: var(--c-copy);
}
a.button.secondary:hover,
.button.secondary:hover {
  border-color: var(--c-copy);
}
a.button:hover:not(.switch),
.button:hover:not(.switch) {
  background: var(--c-copy);
  color: white;
}
a.button.disabled,
.button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
a.button.switch,
.button.switch {
  padding: 0.2rem;
  background: var(--c-copy);
  color: white;
}
a.button.switch > label,
.button.switch > label {
  cursor: pointer;
  flex-grow: 1;
  border-radius: calc(0.7 * var(--radius-small));
  margin: 0;
  font-size: 1em;
  padding: 0.5rem 1rem;
}
a.button.switch > label input,
.button.switch > label input {
  display: none;
}
a.button.switch > label:has(input:checked),
.button.switch > label:has(input:checked) {
  background: var(--c-corporate);
  color: white;
}

.badge {
  position: fixed;
  top: var(--gap);
  left: 50%;
  transform: translateX(-50%);
  padding: 0.2em 0.5em;
  background: var(--c-corporate);
  color: white;
  border-radius: var(--radius-small);
}

.error {
  color: var(--c-rot);
  font-weight: bold;
}
.error.large {
  font-size: var(--fs-large);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.waitforapi {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.waitforapi.hide {
  display: none;
}
.waitforapi img {
  position: absolute;
  bottom: var(--gap-2x);
  right: var(--gap-2x);
  animation: rotate 2s linear infinite;
}

.debug {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow-x: scroll;
  background: #242424;
  color: #24ff00;
}
.debug > div {
  padding: 0.24rem 0.5rem;
  display: flex;
  align-items: center;
}
.debug > div > div {
  display: flex;
  align-items: center;
}
.debug > div > div.click {
  cursor: pointer;
  color: #ffff00;
}
.debug > div > div span {
  padding: 0.24rem 0.5rem;
}
.debug > div span:first-child {
  opacity: 0.5;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.avatar {
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  background: var(--c-grau);
}
.avatar img {
  display: flex;
}
.avatar img:not(.square) {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.avatar img.square {
  opacity: 0;
  height: 8rem;
  width: 8rem;
}

/*
/* Formate
*/
article.format-video iframe {
  max-width: 100%;
}

.credits {
  color: var(--c-copy);
}
.credits p {
  font-size: 1em;
  margin: 0;
  float: left;
  margin: 0 20px 0 0;
}
.credits a {
  text-decoration: none;
}
.credits ul {
  font-size: 1em;
  float: left;
  margin: 0 20px 0 0;
}
.credits ul li {
  float: left;
  background: none;
  padding: 0;
  margin: 0;
}
.credits ul li:after {
  content: ", ";
}
.credits ul li:first-child, .credits ul li:last-child {
  content: "";
}
.credits ul li:first-child:after, .credits ul li:last-child:after {
  content: "";
}

@media (min-width: 550px) {
  .f-left,
  .alignleft {
    float: left;
  }
}

@media (min-width: 550px) {
  .f-right,
  .alignright {
    float: right;
  }
}

.aligncenter,
.alignright,
.alignleft {
  display: block;
  margin: 0 auto;
}
@media (min-width: 550px) {
  .aligncenter,
  .alignright,
  .alignleft {
    display: inline;
    margin: 0 10px 10px 0;
  }
}

@media (min-width: 550px) {
  .alignright {
    display: inline;
    margin: 0 0 10px 10px;
  }
}

.a-left,
.text-left {
  text-align: left;
}

.a-right,
.text-right {
  text-align: right;
}

footer {
  background: white;
  padding: var(--gap) var(--width-margin);
  display: flex;
  align-items: center;
  box-shadow: 0 0 3em rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  footer {
    padding: 2em var(--width-margin);
    flex-direction: column;
  }
}
footer p {
  margin: 0;
  font-weight: bold;
}
footer a {
  color: var(--c-copy);
  text-decoration: none;
}
footer nav {
  text-align: center;
}
footer nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  footer nav ul {
    justify-content: center;
    flex-direction: row;
  }
}
footer nav ul li {
  margin: 0 !important;
  padding: 0 !important;
}
@media (min-width: 768px) {
  footer nav ul li {
    margin: 0 0 0 2em !important;
  }
}

.pr {
  position: relative;
}
.pr.pa1 {
  width: 100%;
  padding-bottom: 100%;
}
.pr.pa1.wide {
  padding-bottom: 56%;
}

.pa {
  position: absolute;
}
.pa.l0 {
  left: 0;
}
.pa.t0 {
  top: 0;
}
.pa.r0 {
  right: 0;
}
.pa.b0 {
  bottom: 0;
}

.pa1 {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.pa1.of {
  object-fit: cover;
}

.fxc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fxc.col {
  flex-direction: column;
}

.fxsb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fxsb.afs {
  align-items: flex-start;
}
.fxsb.afe {
  align-items: flex-end;
}

.txc {
  text-align: center;
}

.hideMove {
  position: fixed;
  top: -5000px;
}

.clear {
  clear: both;
}

.printonly {
  display: none;
}

.wp-caption,
img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.blur-50 {
  -webkit-filter: url("#sharpBlur");
  filter: url("#sharpBlur");
}

.hideSvgSoThatItSupportsFirefox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}