selectplayers {
    &:not(:first-child) {
        margin-top: var(--m-vert);
    }
    &:not(:last-child) {
        margin-bottom: var(--m-vert);
    }
}

.selectplayers {
    .select {
        margin-top: var(--gap-2x);
        display: grid;
        grid-gap: var(--gap);
        @media(min-width: 768px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media(min-width: 1024px){
            grid-template-columns: repeat(3, 1fr);
        }
    }
    .select>div {
        margin-top: 6px;
        border-radius: var(--radius-small);
        background: var(--c-grey-light);
        display: flex;
        justify-content: space-between;
        &>span {
            display: block;
            padding: 0.8em 0.8em;
        }
        .stats {
            font-weight: bold;
            border-left: solid 1px white;
            padding: 0.8em 1.4em;
            width: 6rem;
            text-align: right;
            font-size: var(--fs-small);
            line-height: 1.4;
            &>span {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                img {
                    height: 1em;
                    width: 1em;
                    margin-right: 0.5em;
                }
            }
            .na {
                opacity: 0.24
            }
        }
        &:hover {
            background-color: var(--c-corporate);
            color: white;
            cursor: pointer
        }
        &.selected {
            background-color: var(--c-secondary);
            color: white;
        }
    }
    .submit {
        margin-top: var(--gap-2x);
    }
}