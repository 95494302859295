.tutorial {
    margin: var(--gap-2x) 0;
    border: solid 4px var(--c-corporate);
    border-radius: var(--radius-small);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--gap-2x);
    text-align: center;
}
.cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--gap);
    &.grey {
        &>div {
            padding: var(--gap);
            background: var(--c-grey-light);
            border-radius: var(--radius-small);
        }
    }
}

div.statstable{
    padding: var(--gap);
    border: solid 2px var(--c-corporate);
    margin-bottom: var(--gap-2x);
    p {
        margin: 0;
        display: flex;
        justify-content: space-between;
        span.label {
            font-weight: bold;
        }
    }
}

span.link {
    color: var(--c-corporate);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: var(--c-copy);
    }
}

a.button,
.button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 0.7rem 1.5rem 0.6rem 1.5rem;
    font-size: 1rem;
    background: var(--c-corporate);
    color: white;
    text-decoration: none;
    border-radius: var(--radius-small);
    box-shadow: none;
    border: none;
    text-shadow: none;
    font-family: var(--ff-copy);
    font-weight: bold;
    text-transform: uppercase;
    --webkit-apperance: none;
    text-align: center;
    &.block {
        display: block;
        width: 100%;
    }
    &.small,
    &.mini {
        padding: 0.4em 1em 0.3em 1em;
        font-size: 0.86em;
    }
    &.transparent {
        background: transparent;
        color: var(--c-copy);
        &:hover {
            background: var(--c-corporate);
            color: white;
        }
    }
    &.green {
        background: var(--c-gruen);
    }
    &.secondary {
        background: white;
        border: solid 1px var(--c-secondary);
        color: var(--c-copy);
        &:hover {
            border-color: var(--c-copy);
        }
    }
    &:hover {
        &:not(.switch) {
            background: var(--c-copy);
            color: white;
        }
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &.switch {
        padding: 0.2rem;
        background: var(--c-copy);
        color: white;
        &>label {
            cursor: pointer;
            input {
                display: none;
            }
            &:has(input:checked) {
                background: var(--c-corporate);
                color: white;
            }
            flex-grow: 1;
            border-radius: calc(0.7 * var(--radius-small));
            margin: 0;
            font-size: 1em;
            padding: 0.5rem 1rem;
        }
    }
}

.badge {
    position: fixed;
    top: var(--gap);
    left: 50%;
    transform: translateX(-50%);
    padding: 0.2em 0.5em;
    background: var(--c-corporate);
    color: white;
    border-radius: var(--radius-small);
}

.error {
    color: var(--c-rot);
    font-weight: bold;
    &.large {
        font-size: var(--fs-large);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.waitforapi {
    &.hide {
        display: none;
    }
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
        position: absolute;
        bottom: var(--gap-2x);
        right: var(--gap-2x);
        animation: rotate 2s linear infinite;
    }
}

.debug {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-x: scroll;
    background: #242424;
    color: #24ff00;
    &>div {
        padding: 0.24rem 0.5rem;
        display: flex;
        align-items: center;
        &>div {
            &.click {
                cursor: pointer;
                color: #ffff00;
            }
            display: flex;
            align-items: center;
            span {
                padding: 0.24rem 0.5rem;
            }
        }
        span:first-child {
            opacity: 0.5;
        }
    }
}

.disabled {
        opacity: 0.5;
        pointer-events: none;
}

.avatar {
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    background: var(--c-grau);
    img {
        display: flex;
    }
    img:not(.square) {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
    }
    img.square {
        opacity: 0;
        height: 8rem;
        width: 8rem;
    }
}