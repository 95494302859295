html {
    zoom: 100%;
    * {
        box-sizing: border-box;
    }
    box-sizing: border-box;
}



*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: var(--ff-copy);
    font-size: var(--fs-copy);
    -webkit-font-smoothing: antialiased;
    color: var(--c-copy);
    @extend .typography;
    background: url("/images/Texture-Portrait-AdobeStock_617735627.gif") no-repeat center center fixed;
    background-size: cover;
    @media (min-aspect-ratio: 1/1) {
        background: url("/images/Texture-AdobeStock_617735627.gif") no-repeat center center fixed;
        background-size: cover;
    }
}


#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    main {
        padding: var(--gap-2x) var(--width-margin);
    }
}