.panel {
	padding: var(--gap-2x);
	max-width: 640px;
	&:has(.column) {
		width: 100%;
		max-width: none;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: var(--gap-2x);
	}
}

.form {
	padding: var(--gap-2x);
	box-shadow: var(--shadow);
	border-radius: var(--radius);
	&:not(:first-child) {
		margin-top: var(--gap-2x);
	}
	&:not(:last-child) {
		margin-bottom: var(--gap-2x);
	}
}

.formrow {
	margin-bottom: var(--gap);
	&.cols {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: var(--gap);
		&.cols3 {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}

label {
	margin-bottom: 1em;
	display: block;
	font-size: 0.86em;
}

span.link {
	text-decoration: underline;
}

select,
textarea,
input[type='email'],
input[type='password'],
input[type='search'],
input[type='text'] {
	font-family: var(--ff-cop24);
	padding: 0.8em;
	border: solid 1px var(--c-grey);
	margin: 0;
	width: 100%;
	font-size: 1em;
	border-radius: var(--radius-small);
	&:focus {
		outline: none;
		border-color: var(--c-corporate);
	}
	&:hover {
		border-color: var(--c-corporate);
	}
}

input[type='checkbox'],
input[type='radio'],
input[type='submit'],
input[type='button'],
select {
	cursor: pointer;
}

.switch:not(.button) {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2px;
	width: 100%;
	&>div {
		padding: 0.8em;
		border: solid 2px var(--c-grey-light);
		background: var(--c-grey-light);
		color: var(--c-grey);
		text-align: center;
		cursor: pointer;
		border-radius: 0 var(--radius-small) var(--radius-small) 0;
		&:first-child {		
			border-radius: var(--radius-small) 0 0 var(--radius-small);
		}
		&:hover {
			color: var(--c-copy);
		}
		font-size: 1em;
		&.on {
			background: var(--c-corporate);
			border-color: var(--c-corporate);
			color: white;
		}
	}
}